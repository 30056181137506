<template>
  <div class="container pr-2 my-4">
    <div class="my-6 flex justify-end">
      <router-link to="/gerenciador/classes/adicionar" class="button"
        >Nova classe</router-link
      >
    </div>

    <ul class="list w-full">
      <li v-for="sign in orderedClasses" :key="sign._id">
        <div>
          {{ sign.number }} <strong class="pl-6">{{ sign.titleAdmin }}</strong>
        </div>
        <div class="flex">
          <router-link
            :to="`/gerenciador/classes/${sign._id}`"
            class="button mr-2 green"
          >
            Editar
          </router-link>
          <div class="button red" @click.prevent="remove(sign)">
            Excluir
          </div>
        </div>
      </li>
      <li v-if="classes.length == 0">Nenhuma classe de signo encontrada</li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ClassesList",
  components: {},
  filters: {},
  props: {},
  data: function() {
    return {};
  },
  computed: {
    ...mapState({
      classes: state => state.Classes.list
    }),
    // ...mapGetters({
    //   item: "namespace/item"
    // })
    orderedClasses() {
      return [...this.classes].sort((a, b) => a.number - b.number);
    }
  },
  watch: {},
  async created() {
    if (this.classes.length < 66) {
      await this.$store.dispatch("Classes/list");
    }
  },
  async mounted() {},
  methods: {
    async remove(sign) {
      var shouldRemove = confirm(
        `Deseja mesmo excluir a classe ${sign.number} - ${sign.titleAdmin} ?`
      );
      if (shouldRemove) {
        await this.$store.dispatch("Classes/remove", sign._id);
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
